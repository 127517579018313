import { createRouter, createWebHistory } from "vue-router";

// Import pages dynamically where possible
const Dashboard = () => import("./Pages/Dashboard.vue");
const UserList = () => import("./Pages/Admin/Users.vue");
const UserDetail = () => import("./Pages/Admin/Showuser.vue");
const GamerList = () => import("./Pages/Gamer/Gamers.vue");
const GamerDetail = () => import("./Pages/Gamer/Gamerdetail.vue");
const BoostList = () => import("./Pages/Boost/Boosts.vue");
const NewBoost = () => import("./Pages/Boost/Newboost.vue");
const TaskList = () => import("./Pages/Task/Tasks.vue");
const NewTask = () => import("./Pages/Task/Newtask.vue");
const Register = () => import("./Pages/Auth/Register.vue");
const Login = () => import("./Pages/Auth/Login.vue");

const routes = [
    {
        path: "/dashboard",
        name: "dashboard.index",
        component: Dashboard,
    },
    {
        path: "/users",
        name: "users.index",
        component: UserList,
    },
    {
        path: "/users/:id",
        name: "user.detail",
        component: UserDetail,
    },
    {
        path: "/gamers",
        name: "gamer.index",
        component: GamerList,
    },
    {
        path: "/gamer/:id",
        name: "gamer.detail",
        component: GamerDetail,
    },
    {
        path: "/boosts",
        name: "boost.index",
        component: BoostList,
    },
    {
        path: "/boosts/create",
        name: "NewBoost",
        component: NewBoost,
    },
    {
        path: "/tasks",
        name: "task.index",
        component: TaskList,
    },
    {
        path: "/tasks/create",
        name: "NewTask",
        component: NewTask,
    },
    {
        path: "/register",
        component: Register,
        name: "register",
    },
    {
        path: "/login",
        component: Login,
        name: "login",
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
