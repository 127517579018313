import "../css/app.css";
import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy";
import { InertiaProgress } from "@inertiajs/progress";
import router from "./router";
import feather from "feather-icons";

const appName = "Administrator | Griffin";

createInertiaApp({
    title: (title) => `${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({
            render: () => h(App, props),
        })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(router);

        // Adding Feather Icons to the global properties
        app.config.globalProperties.$feather = feather;

        app.mixin({
            methods: {
                reloadPage() {
                    this.$inertia.reload({ preserveState: false });
                },
            },
        });

        app.mount(el);

        return app;
    },
    progress: {
        color: "#4B5563",
    },
});

// Initialize Inertia Progress Bar
InertiaProgress.init();
